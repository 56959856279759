<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="车队简称">
            <el-input
              placeholder="请输入关键词"
              v-model="conditions.shortname"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="车队名称">
            <el-input
              placeholder="请输入关键词"
              v-model="conditions.name"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              class="buttons"
              @click="handleSearch"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table border :data="tableData" style="width: 100%">
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="shortname"
            label="车队简称"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="name" label="车队名称">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="telephone"
            label="联系电话"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="address" label="地址">
          </el-table-column>
          <el-table-column show-overflow-tooltip label="状态">
            <template v-slot="scope">
              <el-tag
                size="small"
                :type="enumStatus[scope.row.fleetStatus].type"
                >{{ enumStatus[scope.row.fleetStatus].label }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="操作">
            <template v-slot="scope">
              <el-button type="text" size="mini" @click="goDetail(scope.row)">
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      conditions: {
        page: 1,
        limit: 10,
        name: "",
        shortname: "",
      },
      enumStatus: {
        0: { label: "待审核", type: "" },
        1: { label: "正常", type: "success" },
        2: { label: "审核未通过", type: "danger" },
        3: { label: "停用", type: "info" },
      },
      tableData: [],
      total: 0,
    };
  },
  components: {
    Pagination,
  },
  computed: {
    ...mapState(["pendingCount"]),
  },
  created() {
    this.getList();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getPendingCount();
    });
  },
  methods: {
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    async getList() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/FleetInfo/list",
        "get",
        this.conditions
      );
      this.tableData = data.data.list;
      this.total = data.data.totalCount;
    },
    handleSearch() {
      this.conditions.page = 1;
      this.getList();
    },
    goDetail(row) {
      this.$router.push({ path: "/check/fleetDetails", query: { id: row.id } });
    },
    async getPendingCount() {
      const res = await this.$http("api1", "/api/stat/pendingNum", "get");
      for (let item of this.pendingCount) {
        item.count = res.data[item.key];
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
